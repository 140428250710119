import { useEffect, useState } from "react"
import { useLocation, useParams } from "react-router-dom"
import { getBlogById, getPromoById } from "../api";
import { TbArrowsShuffle } from "react-icons/tb";
import { FaFacebookF, FaTwitter } from "react-icons/fa";

export default function Detail() {
    const location = useLocation()
    const { slug } = useParams()
    const api = process.env.REACT_APP_CMS

    const [data, setData] = useState({});

    const getData = async (loc, content) => {
        const type = loc.pathname.split('/')[1];
        let result = [];
        if (type === 'blog') {
            let url = '/api/posts/'+content
            result = await getBlogById(url)
        } else if (type === 'promo') {
            let url = '/api/promos/'+content
            result = await getPromoById(url)
        }
        setData(result)
    }

    const createMarkup = (desc) => {
        const parser = new DOMParser();
        const htmlDoc = parser.parseFromString(desc, 'text/html');
        const img = htmlDoc.querySelector('img')
        if (img !== null) {
          img.src = api + '/uploads/' + img.src.split('uploads')[1];
          img.style.width = '100%'
          img.style.height = '40vh'
          img.style.objectFit = 'contain'
        }
        return {__html: htmlDoc.body.innerHTML};
    }

    useEffect(() => {
        getData(location, slug)
    }, [location, slug])

    return (
        <>
            {Object.keys(data).length > 0 &&
                <>
                {console.log(data.attributes)}
                <div className='relative w-full min-h-[90vh] md:min-h-[80vh] lg:min-h-screen' style={{ backgroundImage: `url('${api + data.attributes.headers_image.data.attributes.url}')`, backgroundOrigin: 'border-box' , backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundAttachment: 'fixed', backgroundPosition: 'center'}}>
                    <div style={{background: 'rgba(0, 0, 0,0.2)'}}>
                    <div className='container mx-auto flex justify-center items-end min-h-[90vh] md:min-h-[80vh] lg:min-h-screen'>
                        <div className='bg-[#34b575] w-[100vh] px-10 py-10 text-xl md:text-3xl lg:text-3xl text-white text-center'>
                        {data.attributes.title}
                        </div>
                    </div>
                    </div>
                </div>
                <div className='container mt-10 px-10'>
                    <div 
                    className='text-gray-500 leading-7'
                    dangerouslySetInnerHTML={createMarkup(data.attributes.body)}
                    ></div>
                    <div className='my-10 text-right flex justify-end'>
                    <div className='bg-gray-300 mx-3 px-3 py-2 lg:px-4 lg:py-3 rounded-lg cursor-pointer hover:bg-gray-400'>
                        <TbArrowsShuffle size={28} className='text-white' />
                    </div>
                    <div className='bg-gray-300 mx-3 px-3 py-2 lg:px-4 lg:py-3 rounded-lg cursor-pointer hover:bg-gray-400'>
                        <FaFacebookF size={28} className='text-white' />
                    </div>
                    <div className='bg-gray-300 mx-3 px-3 py-2 lg:px-4 lg:py-3 rounded-lg cursor-pointer hover:bg-gray-400'>
                        <FaTwitter size={28} className='text-white' />
                    </div>
                    </div>
                </div>
                </>
            }
        </>
    )
}