import React, { useEffect, useState } from 'react'
// import bg_bulurah from '../img/BG_Bulurah.png'
import axios from 'axios';
// import { useMediaQuery } from 'react-responsive'
// import { Navigation, Autoplay } from 'swiper';
// import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';
// import { Link } from 'react-router-dom';
import { getLanding, getLogo, getPartner } from '../api';
import moment from 'moment';
import { FaPhoneAlt } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

export default function Home({refAbout, refFeature}) {
  const [title, setTitle] = useState([])
  const [image_title, setImage_title] = useState('')
  // const [blogs, setBlogs] = useState([])
  const [partner, setPartner] = useState([])
  const [descBulurahAngka, setDescBulurahAngka] = useState([])
  const [whyDesc, setWhyDesc] = useState('');
  const [whyImg, setWhyImg] = useState(null);
  const [dataSolution, setDataSolution] = useState({})
  const [dataPrinciple, setDataPrinciple] = useState([])
  const [descFeature, setDescFeature] = useState("");
  const [dataFeature, setDataFeature] = useState([]);
  const [ctnButton, setCtnButton] = useState("")
  const [urlCtnButton, setUrlCtnButton] = useState("")
  // const [bulurahAngka, setBulurahAngka] = useState([])
  // const [desc_special, setDesc_special] = useState([])
  const [imageSpecial, setImageSpecial] = useState('')
  // const [special, setSpecial] = useState([])
  // const [solutions, setSolutions] = useState([])
  // const [comments, setComments] = useState([])
  const [aboutDesc, setAboutDesc] = useState([]);
  // const isPhoneScreen = useMediaQuery({ query: '(max-width: 480px)' })
  // const isSmallScreen = useMediaQuery({ query: '(max-width: 640px)' })
  // const isMediumScreen = useMediaQuery({ query: '(max-width: 768px)' })
  // const isLargeScreen = useMediaQuery({ query: '(max-width: 1280px)' })
  const api = process.env.REACT_APP_CMS;
  const navigate = useNavigate()

  const getBanner = async () => {
    const result = await getLanding();
    const res_logo = await getLogo();
    setTitle(result.title);
    setWhyDesc(result.why_bulurah);
    setWhyImg(result.why_bulurah_img.data)
    setCtnButton(result.ctn_button)
    setUrlCtnButton(result.link_ctn_button)
    setDescFeature(result.desc_feature)
    setDescBulurahAngka(result.bulurah_dalam_angka_desc.split("\n"))
    setImage_title(api + result.image_title.data?.attributes.url)
    setImageSpecial(api + res_logo.secondary_logo.data?.attributes.url)
  }

  useEffect(() => {
    getBanner()
    getAbout()
    getSolution()
    getPrinciple()
    getFeature()
    getDataPartner()
    // axios.get(`${process.env.REACT_APP_CMS}/api/home?populate=*`)
    //   .then(({ data }) => {
    //     if (data.data) {
    //       const attr = data.data.attributes
    //       // const result = data.data.attributes.title.split("\n").map(word => word.split('**'))
    //       setTitle(data.data.attributes.title);
    //       setWhyDesc(attr.why_bulurah);
    //       setWhyImg(attr.why_bulurah_img.data)
    //       setDescFeature(attr.desc_feature)
    //       // setDesc_special(data.data?.attributes.special_description?.split("\n") || []);
    //       setDescBulurahAngka(data.data.attributes.bulurah_dalam_angka_desc.split("\n"))
    //       setImage_title(process.env.REACT_APP_CMS + data.data?.attributes.image_title.data?.attributes.url)
    //       setImageSpecial(process.env.REACT_APP_CMS + data.data?.attributes.image_special.data?.attributes.url)
    //     }
    //   })
    // axios.get(`${process.env.REACT_APP_CMS}/api/posts?populate[0]=headers_image&sort=date%3Adesc&pagination[limit]=3`)
    //   .then(({ data }) => {
    //     setBlogs(data?.data || []);
    //   })
    // axios.get(`${process.env.REACT_APP_CMS}/api/partners?populate=logo`)
    //   .then(({ data }) => {
    //     if (data?.data.length > 0) {
    //       setPartner(data.data.map(item => item.attributes.logo?.data.attributes.url))
    //     }
    //   })
    // axios.get(`${process.env.REACT_APP_CMS}/api/list-specials?populate=image`)
    //   .then(({ data }) => {
    //     if (data?.data.length > 0) {
    //       setSpecial(data.data?.map(item => ({
    //         id: item.id,
    //         description: item.attributes.description,
    //         image: process.env.REACT_APP_CMS + item.attributes.image.data?.attributes.url
    //       })))
    //     }
    //   })
    // axios.get(`${process.env.REACT_APP_CMS}/api/list-solusions?populate=image`)
    //   .then(({ data }) => {
    //     if (data?.data.length > 0) {
    //       setSolutions(data.data?.map(item => ({
    //         id: item.id,
    //         title: item.attributes.title,
    //         description: item.attributes.description,
    //         image: process.env.REACT_APP_CMS + item.attributes.image.data?.attributes.url
    //       })))
    //     }
    //   })
    // axios.get(`${process.env.REACT_APP_CMS}/api/bulurah-dalam-angkas?populate=*&sort=id`)
    //   .then(({ data }) => {
    //     if (data?.data.length > 0) {
    //       setBulurahAngka(data.data.map(item => ({
    //         id: item.id,
    //         value: item.attributes.value,
    //         name: item.attributes.name,
    //         theme: item.attributes.theme.data?.attributes
    //       })))
    //     }
    //   })
    // axios.get(`${process.env.REACT_APP_CMS}/api/comments`)
    //   .then(({ data }) => {
    //     if (data?.data.length > 0) {
    //       setComments(data.data?.map(item => ({
    //         id: item.id,
    //         title: item.attributes.title,
    //         description: item.attributes.description,
    //       })))
    //     }
    //   })
    // eslint-disable-next-line 
  }, [])

  const getAbout = () => {
    const url = api + '/api/about?populate=*'
    axios.get(url).then(res => {
      const result = res.data.data.attributes
      setAboutDesc(result)
    })
  }

  const getSolution = () => {
    const url = api + '/api/list-solusions?populate=*';
    axios.get(url).then(res => {
      const result = res.data.data
      result.sort((a,b) => moment(b.attributes.updatedAt) - moment(a.attributes.updatedAt))
      setDataSolution(result[0])
    })
  }

  const getPrinciple = () => {
    const url = api + '/api/list-principles?populate=*';
    axios.get(url).then(res => {
      const result = res.data.data
      setDataPrinciple(result)
    })
  }

  const getFeature = () => {
    const url = api + '/api/list-features?populate=*';
    axios.get(url).then(res => {
      const result = res.data.data
      setDataFeature(result)
    })
  }

  const getDataPartner = async () => {
    const result = await getPartner();
    setPartner(result)
  }

  const createMarkup = (desc) => {
    return {__html: desc};
  }

  return (
    <>
      {/* button whatsapp */}
      <div className='fixed right-0 bottom-0 z-10 px-5 py-5'>
        <div className='bg-[#34b575] px-5 py-5 rounded-full cursor-pointer hover:bg-[#36a06b] drop-shadow-4xl'>
          <FaPhoneAlt size={28} className='text-white' />
        </div>
      </div>
      {/* end button */}
      <div className='relative w-full min-h-screen' style={{ backgroundImage: `url('${image_title}')`, backgroundOrigin: 'border-box' , backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundAttachment: 'fixed', backgroundPosition: 'center'}}>
        <div style={{background: 'rgba(0, 0, 0,0.1)'}}>
          <div className='container mx-auto'>
            <div className='flex justify-center items-center h-screen text-white'>
              <div className='w-screen mt-[20%] lg:mt-[15%] px-[10%] lg:px-[20%]'>
                <h1 className=''>{title}</h1>
                <p className='mt-5'>{descBulurahAngka}</p>
                <div className='mt-[5rem] text-center'>
                  {urlCtnButton.indexOf('http') > -1 ? 
                    <a href={`//${urlCtnButton}`}>
                      <button className='border-2 py-3 px-20 rounded-full lg:text-xl hover:bg-white hover:text-black'>{ctnButton}</button>
                    </a>
                  :
                    <button className='border-2 py-3 px-20 rounded-full lg:text-xl hover:bg-white hover:text-black' onClick={() => navigate(urlCtnButton)}>{ctnButton}</button>
                  }
                </div>
              </div>
            </div>
            {/* <div className='flex md:grid md:grid-cols-2 lg:flex justify-center md:justify-between lg:justify-evenly md:pt-8 min-h-screen content-center flex-wrap-reverse md:flex-nowrap lg:flex-wrap gap-y-8 text-white' style={{fontSize: isSmallScreen && '1.25rem' }}>
              <img src={image_title} className='shrink' width={isMediumScreen ? '200px' : '300px'} alt="title home" />
              <h1 className='font-bold self-center text-center md:text-left px-2' style={{ maxWidth: isLargeScreen ? '600px' : "50%"}}>
                {title.map((txt) => {
                  return txt.map((word, id, { length }) => {
                    if (id % 2 === 1) {
                      return <strong className='text-white'>{word}</strong>
                    } if (length === id + 1) {
                      return <>{word}<br /></>
                    } else {
                      return <>{word}</>
                    }
                  })
                })
                }
              </h1>
            </div> */}
          </div>
        </div>
      </div>
      <div className='realtive w-full min-h-[80vh] bg-[#34b575]'>
        <div className='flex justify-center items-center h-[80vh] px-3 lg:px-0'>
          <div className=' rounded-full w-[100%] md:w-[80%] lg:w-[50%]'>
            <div className='border-2 rounded-full flex justify-between '>
              <div className='w-[40%] rounded-l-full bg-white p-1 pr-5 self-center'>
                <img src={imageSpecial} alt="" className='rounded-l-3xl w-[100%]' />
              </div>
              <div className='w-[60%] self-center text-white text-center text-xs md:text-lg lg:text-[22px]'>
                buat usaha lancar dan amanah
              </div>
            </div>
          </div>
          {/* <div className='border-2 border-l-0 p-1 rounded-r-full py-1 text-[12px] md:text-[22px] lg:text-[16px] lg:py-4 text-white px-5'>
          </div> */}
        </div>
      </div>
      <div ref={refAbout} className='bg-[#34b575] border-transparent p-10 w-full'></div>
      <div className='relative w-full min-h-[80vh] bg-white'>
        <div className='flex justify-center align-center'>
          <div className='bg-[#34b575] font-bold py-[1.5rem] lg:py-[2rem] px-10 rounded-b-3xl text-white text-lg lg:text-3xl'>
            Tentang Kami
          </div>
        </div>
        {aboutDesc.length !== 0 && (
          <div className='mt-5 px-[10%] flex flex-wrap lg:flex-nowrap justify-center items-center text-gray-500 py-[10%] lg:py-20'>
              <div className='w-[40%] text-center'>
                  <img src={api + aboutDesc.image1.data.attributes.url} alt="" className='object-contain justify-center object-center w-[100%] lg:p-[25%]' />
              </div>
              <div className='mt-3 lg:mt-0 lg:w-[60%]'>
                <p className='font-thin leading-9 lg:text-justify' dangerouslySetInnerHTML={createMarkup(aboutDesc.description)} />
              </div>
          </div>
        )}
      </div>
      <div className='relative w-full min-h-[80vh] bg-[#34b575]'>
        <div className='flex justify-center align-center'>
          <div className='bg-white font-bold py-[1.5rem] lg:py-[2rem] px-10 rounded-b-3xl text-[#34b575] text-lg lg:text-3xl'>
            Kenapa Bulurah
          </div>
        </div>
        <div className='mt-5 px-[10%] flex flex-wrap lg:flex-nowrap justify-center items-start text-white py-[10%] lg:py-20 lg:m-10'>
            <div className='mt-3 lg:mt-0 lg:w-[60%]'>
              {whyDesc !== "" && 
                <p className='font-thin leading-9 lg:leading-[2rem] text-justify lg:text-lg lg:my-5 lg:mr-10' dangerouslySetInnerHTML={createMarkup(whyDesc)} />
              }
            </div>
            <div className='lg:w-[40%] lg:text-center ml-5'>
              {whyImg !== null && whyImg.length > 0 && 
                whyImg.map((item, key) => 
                    <img key={key} src={api + item.attributes.url} alt="" className='object-cover w-[100%] lg:h-[40vh] my-5 rounded-2xl' />
                )
              }
            </div>
        </div>
      </div>
      <div className='relative w-full min-h-[80vh] bg-[#284f6c]'>
        <div className='flex justify-center items-center'>
          <div className='bg-[#34b575] font-bold py-[1.5rem] lg:py-[2rem] px-10 rounded-b-3xl text-white text-lg lg:text-3xl'>
            Solusi Bulurah
          </div>
        </div>
        {'attributes' in dataSolution && 
        <div className='my-5 px-[10%] flex flex-wrap lg:flex-nowrap justify-center items-start text-white py-[10%] lg:py-20'>
          <div className='lg:w-[45%]'>
            <img src={api + dataSolution.attributes.image.data.attributes.url} alt="" className='w-[100%] object-cover rounded-2xl' />
          </div>
          <div className='lg:w-[55%] ml-5 mt-3 lg:mt-0'>
            <h1 className='text-[#34b575] text-xl lg:text-[32px] lg:leading-9 lg:mt-5'>{dataSolution.attributes.title}</h1>
            <p className='mt-5 text-sm lg:text-[16px] leading-8 lg:leading-[2rem] font-thin'>{dataSolution.attributes.description}</p>
          </div>
        </div>
        }
      </div>
      <div className='relative w-full min-h-[80vh] bg-white'>
        <div className='flex justify-center align-center'>
          <div className='bg-[#284f6c] font-bold py-[1.5rem] lg:py-[2rem] px-10 rounded-b-3xl text-white text-lg lg:text-3xl'>
            Prinsip Bulurah
          </div>
        </div>
        <div className='my-5 px-[10%] flex flex-wrap lg:flex-nowrap justify-center items-stretch text-white py-[10%] lg:py-20'>
          {dataPrinciple.length > 0 && dataPrinciple.map((item, key) => 
            <div className='rounded-full mx-5 my-3 lg:my-0' key={key}>
              <div className='bg-[#284f6c] py-12 rounded-t-2xl'>
                <div className='flex justify-center items-center'>
                  <div className='bg-white rounded-full p-5'>
                    <img src={api + item.attributes.icon.data.attributes.url} alt="" className='object-contain h-[5rem]' />
                  </div>
                </div>
              </div>
              <div className='border-2 border-[#284f6c] p-5 rounded-b-2xl px-10'>
                <p className='font-bold text-[#284f6c]'>{item.attributes.title}</p>
                <p className='mt-3 text-gray-500 font-thin'>{item.attributes.description}</p>
              </div>
            </div>
          )}
        </div>
      </div>
      <div ref={refFeature} className='bg-white p-10 w-full'></div>
      <div className='relative w-full min-h-[80vh] bg-[#34b575]'>
        <div className='flex justify-center align-center'>
          <div className='bg-white py-[1.5rem] lg:py-[2rem] px-16 font-bold rounded-b-3xl text-[#34b575] text-lg lg:text-3xl'>
            FEATURES
          </div>
        </div>
        <div className='my-5 px-[10%] text-white py-[10%] lg:py-20'>
          <p className='font-thin leading-8'>{descFeature}</p>
          <div className='mt-10'>
            {dataFeature.length > 0 && dataFeature.map((item, key) => 
              <div className='mt-5 flex flex-nowrap' key={key}>
                <div className='w-[40%] md:w-[10%] bg-white rounded-l-xl p-5 flex justify-center items-center'>
                  <img src={api + item.attributes.icon.data.attributes.url} alt="" className='lg:h-[3rem]' />
                </div>
                <div className='w-[60%] md:w-[90%] px-5 py-2 border-2 rounded-r-xl'>
                  <p className='lg:text-xl'>{item.attributes.title}</p>
                  <p className='mt-3 font-thin mb-3'>{item.attributes.description}</p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className='relative w-full lg:min-h-[70vh] bg-[#284f6c]'>
        <div className='flex justify-center align-center'>
          <div className='bg-[#34b575] font-bold py-[1.5rem] lg:py-[2rem] px-12 rounded-b-3xl text-white text-lg lg:text-3xl'>
            OUR PARTNERS
          </div>
        </div>
          <div className='mt-5 px-[10%] py-10 flex flex-wrap lg:flex-nowrap justify-center lg:justify-between items-center lg:h-[70vh]'>
            {partner.length > 0 && partner.map((item, key) => 
              <div key={key} className='px-5 bg-white py-5 mx-5 my-5 rounded-lg'>
                <img src={api + item.attributes.logo.data.attributes.url} alt={item.attributes.logo.data.attributes.caption} className='w-[100%] lg:object-contain lg:h-[10vh]' />
              </div>
            )}
              {/* <div className='bg-white rounded-full p-[5rem] lg:p-[6rem] m-3'></div>
              <div className='bg-white rounded-full p-[5rem] lg:p-[6rem] m-3'></div>
              <div className='bg-white rounded-full p-[5rem] lg:p-[6rem] m-3'></div>
              <div className='bg-white rounded-full p-[5rem] lg:p-[6rem] m-3'></div>               */}
          </div>
      </div>
      {/* <h2 className='text-center w-full my-4'>Update Terbaru Bulurah</h2>
      <div className='w-full lg:container py-8 md:px-10 lg:px-0 lg:mx-auto mb-8'>
        <Swiper
          modules={[Navigation]}
          slidesPerView={isMediumScreen ? 1 : isLargeScreen ? 2 : 3}
          spaceBetween={50}
          navigation
          style={{ padding: '0 3rem' }}
        // onTransitionEnd={(e)=>console.log(e.realIndex)}
        >
          {blogs.map((item) =>
            <SwiperSlide key={item.id}>
              <div className='block mx-auto'>
                <Link key={item.id} to={`/blog/${item.attributes.slug}`}>
                  <img className='mb-2 md:mb-3 rounded-2xl' src={process.env.REACT_APP_CMS + item.attributes.headers_image.data.attributes.formats.small.url} width="350px" alt={item.attributes.title} />
                  <p className='text-xl' style={{ maxWidth: '350px' }}>{item.attributes.title}</p>
                </Link>
              </div>
            </SwiperSlide>
          )}
          <SwiperSlide>
            <div className='flex px-3'>
              <div className='mx-auto shrink px-8 text-center rounded-lg border-2 relative' style={{ height: "250px", width: "350px" }}>
                <Link to={'/blog'} className='text-lg m-0 absolute top-1/2 left-1/2 font-bold block ' style={{ color: 'rgba(20, 140, 46, 1)', transform: "translate(-50%, -50%)" }}>Lihat blog lainnya</Link>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
      <div className="w-full" style={{ background: 'linear-gradient(180deg, #EFF7F1 0%, #FFFFFF 100%)' }}>
        <div className='container mx-auto lg:px-24'>
          <div className='py-8 mt-8 lg:flex justify-center'>
            <div className='lg:w-1/2 mb-2 md:mb-0 px-8'>
              <img src={imageSpecial} alt='special_image' />
            </div>
            <div className='lg:w-1/2 px-8'>
              <h2>Kenapa Bulurah Special?</h2>
              <p className='text-justify' style={{ color: 'rgba(112, 112, 112, 1)' }}>
                {desc_special}
              </p>
            </div>
          </div>
          <div className='py-8 px-4 lg:px-0 mb-8'>
            <Swiper
              modules={[Navigation, Autoplay]}
              slidesPerView={isMediumScreen ? 1 : isLargeScreen ? 2 : 3}
              navigation
              autoplay={{ delay: 3000, disableOnInteraction: false, pauseOnMouseEnter: true }}
              style={{ padding: '0 2.5rem' }}
              spaceBetween={50}
            >
              {special.map(item =>
                <SwiperSlide style={{ width: 'auto' }}>
                  <div key={item.id} className='w-full'>
                    <div style={{ backgroundImage: `url("${item.image}")`, backgroundColor: 'rgba(54, 158, 76, 1)', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition:'center' }} className='rounded-3xl h-48 flex justify-center items-center mb-6'>
                    </div>
                    <p className='text-justify' style={{ color: 'rgba(112, 112, 112, 1)' }}>{item.description}</p>
                  </div>
                </SwiperSlide>
              )}
            </Swiper>
          </div>
        </div>
      </div>
      <div className="w-full" style={{ background: "linear-gradient(0deg, #EFF7F1 0%, #FFFFFF 100%)" }}>
        <h2 className='text-center w-full my-4' style={{ padding: isPhoneScreen && '0 1rem', fontSize: isPhoneScreen && '1.25rem' }} >Solusi-solusi yang Bulurah Miliki</h2>
        <div className='md:container mx-auto grid grid-cols-2 md:flex justify-center flex-wrap lg:px-24 py-8 sm:px-4 mb-8'>
          {solutions.map((item, idx) =>
            <div key={item.id} style={{ color: 'rgba(112, 112, 112, 1)', width: isPhoneScreen ? '11rem' : isSmallScreen ? '12rem' : isMediumScreen ? '13rem' : '16rem' }} className='w-44 sm:w-52 md:w-64 px-4 sm:px-8 mb-8'>
              <div className='md:h-48 mb-2 md:mb-4 flex justify-center items-center'>
                <img src={item.image} alt={`solution-${idx}`} className='mx-auto' />
              </div>
              <h3 className='font-bold mb-2' style={{ fontSize: isPhoneScreen && '1rem' }}>{item.title}</h3>
              <p className='text-justify' style={{ fontSize: isPhoneScreen && '0.7rem' }}>{item.description}</p>
            </div>
          )}
        </div>
      </div>
      <div className='relative w-full'>
        <div className='lg:flex relative z-10 justify-center container px-4 lg:px-24 my-16 mx-auto'>
          <div className='px-4 mt-8 lg:w-1/2'>
            <h2>Bulurah Dalam Angka</h2>
            <p style={{ color: 'rgba(151, 151, 151, 1)' }}>
              {descBulurahAngka.map(text => <>{text}<br /></>)}
            </p>
          </div>
          <div className='px-4 mt-8 lg:w-1/2 self-start flex flex-wrap'>
            {bulurahAngka.map(item =>
              <div className='py-1' style={{ fontSize: isPhoneScreen && '0.5rem', minHeight: '4rem', width: (item.theme?.length || '100%') }}>
                <div key={item.id} className='border rounded-2xl items-center mr-2 sm:mr-3 flex py-4 sm:py-2 px-3 sm:px-5 h-full' style={{ background: item.theme?.background_color || 'white' }}>
                  <p className='mr-2' style={{ color: item.theme?.text_color || 'black' }}>{item.value}</p>
                  <p style={{ color: item.theme?.text_color || 'black' }}>{item.name}</p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className='py-8 w-full'>
        <h2 className='text-center w-full my-4'>Partner Bulurah</h2>
        <div className='mb-4 lg:container sm:px-8 mx-2 sm:mx-auto'>
          <Swiper
            modules={[Navigation, Autoplay]}
            spaceBetween={50}
            slidesPerView={isMediumScreen ? 2 : isLargeScreen ? 3 : 5}
            navigation
            autoplay={{ delay: 3000, disableOnInteraction: false, pauseOnMouseEnter: true }}
            style={{ padding: '2rem 3.5rem' }}
          >
            {partner.map((item, idx) =>
              <SwiperSlide key={idx + 1} className='overflow-visible self-center'>
                <img src={process.env.REACT_APP_CMS + item}
                  className='mx-auto zoom-animation'
                  onMouseOver={(e) => {
                    if (isSmallScreen) {
                      const swiperElm = e.target.parentElement.parentElement.parentElement;
                      swiperElm.children[0].classList.add('hide')
                      swiperElm.children[1].classList.add('hide')
                    }
                  }}
                  onMouseOut={(e) => {
                    const swiperElm = e.target.parentElement.parentElement.parentElement;
                    swiperElm.children[0].classList.remove('hide')
                    swiperElm.children[1].classList.remove('hide')
                  }}
                  alt={`partner${idx + 1}`} />
              </SwiperSlide>
            )}
          </Swiper>
        </div>
      </div>
      <div className='relative w-full pb-16'>
        <div className='relative z-10'>
          <h2 className='text-center w-full my-4'>Apa Kata Mereka Tentang Bulurah</h2>
          <div className='container mx-auto lg:px-24 py-8'>
            <Swiper
              modules={[Navigation, Autoplay]}
              slidesPerView={isMediumScreen ? 1 : isLargeScreen ? 2 : 3}
              navigation
              autoplay={{ delay: 3000, disableOnInteraction: false, pauseOnMouseEnter: true }}
              style={{ padding: '0 2rem' }}
            >
              {comments.map(item =>
                <SwiperSlide style={{ width: 'auto' }}>
                  <div className='px-8 py-2'>
                    <div key={item.id} style={{ color: 'rgba(112, 112, 112, 1)', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }} className='bg-white w-full py-8 px-8 rounded-3xl'>
                      <h3 className='italic mb-4'>"{item.title}"</h3>
                      <p className='text-justify'>{item.description}</p>
                    </div>
                  </div>
                </SwiperSlide>
              )}
            </Swiper>
          </div>
        </div>
        <div className='absolute bottom-0 w-full h-1/2' style={{ background: "linear-gradient(0deg, #EFF7F1 0%, #FFFFFF 100%)" }}></div>
      </div> */}
    </>
  )
}
