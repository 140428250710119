import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import googlePlay from './img/google-play.png'

export default function Navbar(props) {
    const { pathname } = useLocation();

    const LinkHeader = ({ children, to, ...props }) => {

        return (
            <Link
                className='menu flex md:mr-5'
                to={to}
                {...props}
                // onClick={() => window.scrollY(0)}
            >
                <p
                    className='inline self-center px-2 md:px-4 lg:px-8 text-md lg:text-md text-gray-500'
                    // className={`mb-0 text-xl inline self-center ${pathname === to && ' border-b-4'}`}
                    style={{ color: (to !== '/' ? pathname.indexOf(to) > -1 : pathname === to)  && 'rgba(54, 158, 76, 1)', borderColor: 'transparent' }}
                    >
                    {children}
                </p>
            </Link>
        );
    }
    const triggerNav = () => {
        document.getElementById('hamburger').classList.toggle("active");
        document.getElementById('collapse').classList.toggle("show");
    }
    return (
        <div className='sticky lg:fixed top-0 w-full border-b-2 md:border-b-0 bg-white z-50 drop-shadow-sm'>
            <div className='md:flex justify-start md:justify-between mx-auto container md:px-4 md:py-2'>
                <div className='md:w-[40%] lg:w-[50%] flex justify-between py-1 md:justify-start px-4 md:px-0'>
                    <Link to='/' className='flex flex-row mb-0'>
                        {props?.logo ? <img src={props?.logo} className='h-[4rem]' alt="logo" /> : ''}
                    </Link>
                    <div className='block md:hidden' id='hamburger' onClick={triggerNav}>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
                <div id='collapse' className='relative md:flex flex-nowrap md:border-b-0'>
                    {pathname !== '/' ? 
                        <LinkHeader to='/'>
                            Home
                        </LinkHeader>
                    : 
                        <>
                            <div className='flex self-center menu lg:px-5 pb-5 md:pt-5 lg:pt-0 lg:pb-0'>
                                <p className='inline px-5 text-md lg:text-md text-gray-500 cursor-pointer hover:text-[#369e4c] border-transparent' onClick={() => props.refAbout.current.scrollIntoView({ behavior: 'smooth'})}>
                                    About
                                </p>
                            </div>
                            <div className='flex self-center menu lg:px-5 pb-5 md:pt-5 lg:pt-0 lg:pb-0'>
                                <p className='inline px-5 text-md lg:text-md text-gray-500 cursor-pointer hover:text-[#369e4c] border-transparent' onClick={() => props.refFeature.current.scrollIntoView({ behavior: 'smooth'})}>
                                    Feature
                                </p>
                            </div>
                        </>
                    }
                    <LinkHeader to='/blog'>
                        Blog
                    </LinkHeader>
                    <LinkHeader to='/promo'>
                        Promo
                    </LinkHeader>
                    <LinkHeader to="/faq">
                        FAQ
                    </LinkHeader>
                    <div>
                        <a href={props.apk} target='_blank' rel="noreferrer" className='lg:hidden'>
                            <img src={googlePlay} className='w-[30%] md:w-[100%] md:py-6 object-contain' alt='googleplay' />
                        </a>
                    </div>
                </div>
                <div className='hidden lg:block md:self-center'>
                    <a href={props.apk} target='_blank' rel="noreferrer" className='md:self-center'>
                        <img src={googlePlay} className='h-[2.5rem] md:self-center' alt='googleplay' />
                    </a>
                </div>
            </div>
        </div>
    )
}