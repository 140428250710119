import axios from "axios";
import Swal from "sweetalert2";

const api = process.env.REACT_APP_CMS;
let alertError = false;
let obj = []

const handleError = () => {
    if (alertError) {
        Swal.fire('Something wrong!', 'An unexpected error occurrred.', 'error')
    }
}

const getLogo = () => {
    const url = api + '/api/logo?populate=*';
    return axios.get(url)
    .then(res => {
        return res.data.data.attributes;
    })
    .catch(() => {
        alertError = true
        handleError();
    })
}

const getLink = () => {
    const url = api + '/api/link'
    return axios.get(url)
    .then(res => {
        return res.data.data.attributes
    })
    .catch(() => {
        alertError = true;
        handleError();
    })
}

const getLanding = () => {
    const url = api + '/api/home?populate=*'
    return axios.get(url)
    .then(res => {
        return res.data.data.attributes
    })
    .catch(() => {
        alertError = true;
        handleError()
    })
} 

const getPartner = () => {
    const url = api + '/api/partners?populate=*'
    return axios.get(url)
    .then(res => {
        return res.data.data
    })
    .catch(() => {
        alertError = true
        handleError()
    })
}

const getPromo = () => {
    const url = api + '/api/promos?populate=headers_image&sort[0]=createdAt:desc';
    return axios.get(url)
    .then(res => {
        return res.data.data
    })
    .catch(() => {
        alertError = true
        handleError()
        return obj;
    })
}

const getBlogById = (url) => {
    return axios.get(api + url)
    .then(res => {
        return res.data.data
    })
    .catch(() => {
        alertError = true
        handleError()
    })
}

const getPromoById = (url) => {
    return axios.get(api + url)
    .then(res => {
        return res.data.data
    })
    .catch(() => {
        alertError = true
        handleError()
    })
}

export { getLogo, getLink, getLanding, getPartner, getPromo, getBlogById, getPromoById };