import React, { useEffect, useState } from 'react'
// import axios from 'axios'
import Navbar from './Navbar'
import Footer from './Footer'
import { Outlet } from 'react-router-dom'
import { getLink, getLogo } from './api'

export default function Layout({refAbout, refFeature}) {
    // const [logo, setLogo] = useState('')
    const [navLogo, setNavLogo] = useState('')
    const [link, setLink] = useState({});

    const api = process.env.REACT_APP_CMS

    const handleData = async () => {
        const logo_result = await getLogo();
        const link_result = await getLink()
        if ('secondary_logo' in logo_result) {
            setNavLogo(api + logo_result.secondary_logo.data.attributes.url)
        }
        setLink(link_result)
    }

    useEffect(() => {
        handleData()
        // axios.get(`${process.env.REACT_APP_CMS}/api/logo?populate=*`)
        //     .then(({ data }) => {
        //         const {
        //             main_logo,
        //             secondary_logo
        //         } = data.data?.attributes;
        //         if (main_logo?.data) {
        //             setLogo(process.env.REACT_APP_CMS + main_logo.data.attributes.url)
        //         }
        //         if (secondary_logo?.data) {
        //             setNavLogo(process.env.REACT_APP_CMS + secondary_logo.data.attributes.url)
        //         }
        //     })
        // axios.get(`${process.env.REACT_APP_CMS}/api/link`)
        //     .then(({ data }) => {
        //         setLink(data.data?.attributes)
        //     })
        // eslint-disable-next-line 
    }, [])

    return (
        <div style={{ minHeight: '100vh' }} className='flex flex-col items-center'>
            <Navbar logo={navLogo} apk={link.apk} refAbout={refAbout} refFeature={refFeature} />
            <Outlet />
            <Footer logo={navLogo} link={link} refAbout={refAbout} refFeature={refFeature} />
        </div>
    )
}
