// import axios from 'axios'
import React from 'react'
import FeatherIcon from 'feather-icons-react'
import moment from 'moment/moment'
import googlePlay from './img/google-play.png'
import { useLocation, useNavigate } from 'react-router-dom'

export default function Footer(props) {
  // const [menu, setMenu] = useState([])
  const navigate = useNavigate();
  const { pathname } = useLocation();
  
  // useEffect(() => {
  //   axios.get(`${process.env.REACT_APP_CMS}/api/footer-menus?populate=*`)
  //     .then(({ data }) => {
  //       if (data?.data.length > 0) {
  //         setMenu(data?.data.map(item => ({
  //           id: item.id,
  //           name: item.attributes.name,
  //           sub_menu: item.attributes.sub_menu.data?.map(link => ({ id: link.id, ...link.attributes }))
  //         })))
  //       }
  //     })
  // }, [])

  return (
    <div className='mt-auto w-full bg-white'>
      <div className='mx-auto'>
        <div className='md:flex justify-start md:justify-between mx-auto container md:px-4 md:py-2 my-16'>
        {/* <div className='container flex flex-wrap lg:flex-nowrap lg:justify-between py-16'> */}
          {/* social media */}
          <div className='flex w-[100%] md:w-[30%] lg:w-[48%] justify-center sm:justify-center md:justify-center items-center lg:justify-start'>
              <a className='mx-5 text-2xl' href={props.link.facebook || '#'} target="_blank" rel="noreferrer">
                <FeatherIcon className='inline w-[2rem] h-[2rem]' icon='facebook' />
              </a>
              <a className='mx-5 text-2xl' href={props.link.instagram || '#'} target="_blank" rel="noreferrer">
                <FeatherIcon className='inline w-[2rem] h-[2rem]' icon='instagram' />
              </a>
              <a className='mx-5 text-2xl' href={props.link.twitter || '#'} target="_blank" rel="noreferrer">
                <FeatherIcon className='inline w-[2rem] h-[2rem]' icon='twitter' />
              </a>
          </div>
          <div className='flex mt-5 md:mt-0 lg:mt-0 flex-wrap lg:flex-nowrap justify-center lg:justify-between px-[1rem] lg:px-0'>
            {pathname === '/' ?
              <>
                <div className='inline self-center menu px-5 py-5 lg:py-0'>
                    <p className='px-5 text-md lg:text-md text-[#284f6c] cursor-pointer hover:text-[#369e4c] border-transparent' onClick={() => props.refAbout.current.scrollIntoView({ behavior: 'smooth'})}>
                        About
                    </p>
                </div>
                <div className='inline self-center menu px-5 py-5 lg:py-0'>
                    <p className='px-5 text-md lg:text-md text-[#284f6c] cursor-pointer hover:text-[#369e4c] border-transparent' onClick={() => props.refFeature.current.scrollIntoView({ behavior: 'smooth'})}>
                        Feature
                    </p>
                </div>
              </>
            : 
              <div className='inline self-center menu px-5 py-5 lg:py-0'>
                  <p className={`px-5 text-md lg:text-md text-[#284f6c] cursor-pointer hover:text-[#369e4c] border-transparent ${pathname === '/'? 'text-[#369e4c]' : ''}`} onClick={() => navigate('/')}>
                      Home
                  </p>
              </div>
            }
            <div className='inline self-center menu px-5 py-5 lg:py-0'>
                <p className={`px-5 text-md lg:text-md text-[#284f6c] cursor-pointer hover:text-[#369e4c] border-transparent ${pathname.indexOf('blog') > -1 ? 'text-[#369e4c]' : ''}`} onClick={() => navigate('/blog')}>
                    Blog
                </p>
            </div>
            <div className='inline self-center menu px-5 py-5 lg:py-0'>
                <p className={`px-5 text-md lg:text-md text-[#284f6c] cursor-pointer hover:text-[#369e4c] border-transparent ${pathname.indexOf('promo') > -1 ? 'text-[#369e4c]' : ''}`} onClick={() => navigate('/promo')}>
                    Promo
                </p>
            </div>
            <div className='self-center menu px-5 py-5 lg:py-0'>
                <p className={`px-5 text-md lg:text-md text-[#284f6c] cursor-pointer hover:text-[#369e4c] border-transparent ${pathname.indexOf('faq') > -1 ? 'text-[#369e4c]' : ''}`} onClick={() => navigate('/faq')}>
                    FAQ
                </p>
            </div>
            
          </div>
          <div className='self-center flex justify-center'>
                <a href={props.link.apk} target='_blank' rel="noreferrer" className='md:self-center'>
                    <img src={googlePlay} className='h-[2rem] lg:h-[2.5rem] md:self-center' alt='googleplay' />
                </a>
            </div>

          {/* <div className='flex justify-center md:justify-start flex-wrap'>
            {menu.map(item =>
              <div key={item.id} className='px-4 justify-self-center sm:mr-14 md:mr-8 mb-8 md:mb-0'>
                <p className='font-bold mb-3'>{item.name}</p>
                {item.sub_menu.map((link) =>
                  <a key={item.id + link.id} href={link.link} className='block mb-3'>{link.name}</a>)}
              </div>
            )}
          </div> */}
          {/* <div className='flex flex-col self-center sm:self-start' style={{ width: '8rem' }}>
            {props?.logo ? <img src={props?.logo} className='mb-3' style={{ width: '7rem' }} alt="logo" /> : ''}
            <div className='w-auto' style={{ width: '8rem', color: 'rgba(20, 140, 46, 1)' }}>
              <a className='mx-2' href={props.link.instagram || '#'} >
                <FeatherIcon className='inline' icon='instagram' />
              </a>
              <a className='mx-2' href={props.link.twitter || '#'} >
                <FeatherIcon className='inline' icon='twitter' />
              </a>
              <a className='mx-2' href={props.link.youtube || '#'} >
                <FeatherIcon className='inline' icon='youtube' />
              </a>
            </div>
          </div> */}
        </div>
        {/* <div className='border-t text-center py-8' style={{ borderColor: '#979797', color: "#979797" }}>
          Copyright Bulurah 2022
        </div> */}
      </div>
      <div className='bg-[#34b575] text-center lg:text-left lg:px-[10%] text-white p-2'>
          <a href="https://api.bulurah.com/policy" className="hover:underline">Privacy Policy</a> &nbsp; | &nbsp;  © {moment().year()} Koperasi Digital Semesta Mandiri
        </div>
    </div>
  )
}
