import axios from 'axios'
import React, { useEffect, useState } from 'react'
// import google_play from '../img/google-play.png'
import { useMediaQuery } from 'react-responsive'
import FeatherIcon from 'feather-icons-react'

export default function Faq() {
  // const [googlePlay, setGooglePlay] = useState('')
  // const [description, setDescription] = useState([])
  const [FaQ, setFaQ] = useState([])
  const [selectedFaq, setselectedFaq] = useState({})
  // const [image1, setImage1] = useState('')
  // const [image2, setImage2] = useState('')
  const isMediumScreen = useMediaQuery({ query: '(max-width: 768px)' })

  useEffect(() => {
    // axios.get(`${process.env.REACT_APP_CMS}/api/link`)
    //   .then(({ data }) => {
    //     setGooglePlay(data.data?.attributes.apk)
    //   })
    // axios.get(`${process.env.REACT_APP_CMS}/api/about?populate=*`)
    //   .then(({ data }) => {
    //     setDescription(data.data?.attributes.description.split("\n") || [])
    //     setImage1(process.env.REACT_APP_CMS + data.data?.attributes.image1.data?.attributes.url)
    //     setImage2(process.env.REACT_APP_CMS + data.data?.attributes.image2.data?.attributes.url)
    //   })
    axios.get(`${process.env.REACT_APP_CMS}/api/faqs?sort[0]=id:asc`)
      .then(({ data }) => {
        if (data?.data.length > 0) {
          setFaQ(data.data)
          // setFaQ(data.data?.map(item => ({
          //   id: item.id,
          //   ...(item.attributes)
          // })))
          setselectedFaq(data.data[0].attributes)
        }
      })
  }, [])

  const triggerFaQ = (e, idx) => {
    if (isMediumScreen) {
      e.currentTarget.parentElement.classList.toggle("show-faq")
    } else {
      setselectedFaq(FaQ[idx].attributes)
    }
  }

  const createMarkup = (desc) => {
    const parser = new DOMParser();
    const htmlDoc = parser.parseFromString(desc, 'text/html');
    return {__html: htmlDoc.body.innerHTML};
  }

  return (
    <>
      {/* <div className='w-full min-h-screen' style={{ background: 'linear-gradient(180deg, #EFF7F1 17.26%, rgba(255, 255, 255, 0) 100%)' }}>
        <div className='pt-8 container mx-auto px-8 md:px-24 justify-center min-h-screen items-center lg:flex content-center'>
          <div className='md:pr-24'>
            <h1>
              About Us
            </h1>
            <p className='mb-4'>
              {description.map(word => <>{word}<br /></>)}
            </p>
            <div>
              <a href={googlePlay || '#'}>
                <img src={google_play} className='mb-4 hidden md:inline' style={{ height: '3rem' }} alt='googleplay' />
              </a>
            </div>
              <img src={image2} className='w-full md:w-64 my-4 md:my-0 px-4 md:px-0' alt='title_image' />
          </div>
            <img src={image1} className='w-full md:w-64 my-4 md:my-0 px-8 md:px-0' alt='second_title_image' />
        </div>
      </div> */}
      <div className='relative w-full my-5 md:my-0 lg:my-10 container'>
        <div className='mx-4 sm:mx-14 md:container py-4 md:py-0 lg:py-8 my-0 md:my-4 lg:my-16 px-5 md:px-8 md:mx-auto relative z-10 rounded-2xl'>
          <div className='md:flex justify-center'>
            <div className='px-0 md:px-4 my-4 md:w-1/2 md:border-r'>
              <h2 className='mb-4'>FaQ</h2>
              <div className='md:underline text-[#34b575]'>
                {FaQ.length > 0 && FaQ.map((item, idx) =>
                  <div key={idx} className={`visible ${idx === 0 ? ' show-faq' : ''}`}>
                    <div className=' flex justify-between py-3 px-4 cursor-pointer md:border-0' onClick={(e) => triggerFaQ(e, idx)}>
                      <div className='!text-[#34b575]' dangerouslySetInnerHTML={createMarkup(item.attributes.question)}></div>
                      {isMediumScreen &&
                        <div className='self-center min-w-8'>
                          <FeatherIcon icon='chevron-down' />
                        </div>
                      }
                    </div>
                    <div className='overflow-hidden h-0 my-2'>
                      <p className='text-justify text-gray-500' dangerouslySetInnerHTML={createMarkup(item.attributes.answer)}></p>
                    </div>
                  </div>
                )}
              </div>
            </div>
            
            <div className='px-4 my-4 overflow-y-auto hidden md:block md:w-1/2 self-start flex-wrap' style={{ maxHeight: '70vh' }}>
              <h3 className='my-4' dangerouslySetInnerHTML={createMarkup(selectedFaq.question)}></h3>
              <p className='text-justify' style={{ color: 'rgba(112, 112, 112, 1)' }} dangerouslySetInnerHTML={createMarkup(selectedFaq.answer)}></p>
            </div>
          </div>
        </div>
        <div className='absolute bottom-0 w-full h-1/2'></div>
      </div>
    </>
  )
}
