import React, { useRef } from 'react'
import Home from './Page/Home'
import Layout from './Layout'
import Faq from './Page/Faq'
import { BrowserRouter, Route, Routes } from "react-router-dom"
import Blog from './Page/Blog'
import Promo from './Page/Promo'
import Detail from './component/detail'

export default function App() {
  const refAbout = useRef();
  const refFeature = useRef();
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout refAbout={refAbout} refFeature={refFeature} />}>
          <Route index element={<Home refAbout={refAbout} refFeature={refFeature} />} />
          <Route path="faq" element={<Faq />} />
          <Route path="blog">
            <Route index element={<Blog />} />
            <Route path=":slug" element={<Detail />} />
          </Route>
          <Route path="promo">
            <Route index element={<Promo />} />
            <Route path=":slug" element={<Detail />} />
          </Route>
          <Route path='*' element={<Home />} />
        </Route>
      </Routes >
    </BrowserRouter>
  )
}
