import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import { Navigation, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';
import { getPromo } from '../api';
import NotFound from '../component/not-found';
import LoadPage from '../component/loadPage';

export default function Promo() {
    const [promo, setPromo] = useState([])
    const [trending, setTrending] = useState([])
    const [visible, setVisible] = useState(10);
    const [isLoad, setIsLoad] = useState(false)
    const isSmallScreen = useMediaQuery({ query: '(max-width: 900px)' })
    const isMediumScreen = useMediaQuery({ query: '(max-width: 2000px)' })
    
    const api = process.env.REACT_APP_CMS ;
    const navigate = useNavigate();
        
    useEffect(() => {
        setIsLoad(true)
        getData();
    }, [])

    const getData = async () => {
        const result = await getPromo();
        const trends = [];
        result.length > 0 && result.forEach(item => {
            let is_highlight = item.attributes.is_highlight;
            if (is_highlight === true) {
                trends.push(item)
            }
        })
        const trend_filter = trends.slice(0, 3);
        setTrending(trend_filter)
        const all_promo = [];
        result.length > 0 && result.forEach((item, key) => {
            if (trend_filter[key] === undefined) {
                all_promo.push(item)
            }
        })
        setIsLoad(false)
        setPromo(all_promo)
    }

    const showMoreItems = () => {
        setVisible((prevValue) => prevValue + 10)
    }

    const createMarkup = (desc) => {
        let type = ''
        if (desc.length >= 200) {
            type = '...'
        } else {
            type = ''
        }
        return {__html: (isSmallScreen && desc.substring(0,200) + type) || (isMediumScreen && desc.substring(0,200) + type) };
    }

    return (
        <>
            {!isLoad ? 
                <>
                    {promo.length > 0 || trending.length > 0 ? 
                        <>
                            <Swiper
                                modules={[Navigation, Autoplay]}
                                slidesPerView={1}
                                autoplay={{ delay: 5000 }}
                                navigation={isSmallScreen ? false : true}
                                className='w-[100%]'
                            >   
                                {trending.length > 0 && trending.slice(0,3).map((item, key) => 
                                    <SwiperSlide key={key}>
                                        <div className='relative w-full min-h-screen' style={{ backgroundImage: `url('${api + item.attributes.headers_image.data.attributes.url}')`, backgroundOrigin: 'border-box' , backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundAttachment: 'fixed', backgroundPosition: 'center'}}>
                                            <div style={{background: 'rgba(0, 0, 0,0.4)'}}>
                                                <div className='container mx-auto'>
                                                    <div className='flex justify-center items-center h-screen text-white'>
                                                        <div className='w-screen mt-[20%] lg:mt-[15%] px-[10%] lg:px-[20%]'>
                                                        <h1 className=''>{item.attributes.title}</h1>
                                                        <p className='mt-5 font-thin' dangerouslySetInnerHTML={createMarkup(item.attributes.body)}></p>
                                                        <div className='mt-[5rem] text-center'>
                                                            <button
                                                                className='border-2 border-white py-3 px-20 rounded-full lg:text-xl hover:bg-white hover:text-black'
                                                                onClick={() => navigate(`/promo/${item.attributes.slug}`)}
                                                            >
                                                                READ MORE
                                                            </button>
                                                        </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                )}
                            </Swiper>
                            {promo.length > 0 &&
                                <div className={`${trending > 0 ? 'mt-10' : 'mt-[6%]' } w-[100%] px-10 container flex flex-wrap lg:flex-nowrap justify-center items-start`}>
                                    <div className='w-[100%] lg:w-[50%]'>
                                        <img src={api + promo[0].attributes.headers_image.data.attributes.url} alt="" className='w-[100%] h-[40vh] object-cover' />
                                        <h2 className='my-3 text-[#34b575]'>{promo[0].attributes.title}</h2>
                                        <p className='font-thin text-gray-500' dangerouslySetInnerHTML={createMarkup(promo[0].attributes.body)}></p>
                                        <div 
                                            className='text-blue-600 cursor-pointer text-right hover:underline'
                                            onClick={() => navigate(`/promo/${promo[0].attributes.slug}`)}
                                        >
                                            Read More
                                        </div>
                                    </div>
                                    <div className='w-[100%] lg:w-[50%] mt-5 lg:mt-0 lg:ml-10'>
                                        {promo.slice(1,4).map((item, key) => 
                                            <div className='w-[100%] flex flex-wrap lg:flex-nowrap lg:pb-3' key={key}>
                                                <div className='w-[100%] md:w-[40%] lg:w-[30%] md:mb-3 flex justify-center items-center'>
                                                    <img src={api + item.attributes.headers_image.data.attributes.url} alt="" className='w-[100%] md:h-[15vh] lg:h-[20vh] object-cover' />
                                                </div>
                                                <div className='w-[100%] md:w-[60%] lg:w-[70%] py-3 md:py-0 lg:py-0 md:pl-5 lg:pl-5'>
                                                    <h4 className='pb-3 text-[#34b575] lg:text-xl'>{item.attributes.title}</h4>
                                                    <p className='font-thin text-gray-500' dangerouslySetInnerHTML={createMarkup(item.attributes.body)}></p>
                                                    <div 
                                                        className='text-blue-600 cursor-pointer text-right hover:underline'
                                                        onClick={() => navigate(`/promo/${item.attributes.slug}`)}
                                                    >
                                                        Read More
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            }
                            <div className='mt-0 lg:mt-10 px-10 container'>
                                {promo.length > 0 &&
                                    promo.slice(4, visible).map((item, key) => 
                                        <div className='w-[100%] flex flex-wrap lg:flex-nowrap lg:pb-5' key={key}>
                                            <div className='w-[100%] md:w-[40%] lg:w-[20%] md:mb-3'>
                                                <img src={api + item.attributes.headers_image.data.attributes.url} alt="" className='w-[100%] md:h-[15vh] lg:h-[16vh] object-cover' />
                                            </div>
                                            <div className='w-[100%] md:w-[60%] lg:w-[80%] py-3 md:py-0 lg:py-0 md:pl-5 lg:pl-5'>
                                                <h4 className='pb-3 text-[#34b575] lg:text-xl'>{item.attributes.title}</h4>
                                                <p className='font-thin text-gray-500' dangerouslySetInnerHTML={createMarkup(item.attributes.body)}>
                                                </p>
                                                <div 
                                                    className='text-blue-600 cursor-pointer text-right hover:underline'
                                                    onClick={() => navigate(`/promo/${item.attributes.slug}`)}
                                                >
                                                    Read More
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                                {visible < promo.length &&
                                    <div className='text-center mt-5'>
                                        <button 
                                            className='px-10 py-3 lg:py-4 bg-[#34b575] text-white rounded-full text-lg lg:text-xl drop-shadow-lg hover:bg-[#359f6a]'
                                            onClick={() => showMoreItems()}
                                        >
                                            Load More
                                        </button>
                                    </div>
                                }
                            </div>
                        </>
                    :
                        <NotFound text="promo" />
                    }
                </> 
            :
                <LoadPage />
            }
            
        </>
    )
}
