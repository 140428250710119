import image from '../assets/img/not-found.jpg'

export default function NotFound({text}) {
    return (
        <div className='lg:mt-[5%] flex justify-center items-center'>
            <div className='text-center'>
                <div className='text-center px-[10%] md:px-[20%] lg:px-[25%]'>
                    <img src={image} alt="" className='w-[100%]' />
                </div>
                <div className='mt-[-2rem] md:mt-[-4rem] lg:mt-[-3rem]'>
                    Sorry, {text} is empty!
                </div>
            </div>
        </div>
    )
}