import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import { Navigation, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';
import NotFound from '../component/not-found';
import LoadPage from '../component/loadPage';

export default function Blog() {
    const [blogs, setBlogs] = useState([])
    const [trending, setTrending] = useState([])
    const [visible, setVisible] = useState(10);
    const [isLoad, setIsLoad] = useState(false)
    // const [categories, setCategories] = useState([])
    // const [searchParams] = useSearchParams();
    // const [category, setCategory] = useState('')
    const isSmallScreen = useMediaQuery({ query: '(max-width: 900px)' })
    const isMediumScreen = useMediaQuery({ query: '(max-width: 2000px)' })
    const api = process.env.REACT_APP_CMS ;
    const navigate = useNavigate();

    // useEffect(() => {
    //     const query_category = searchParams.get("category");
    //     const query_page = searchParams.get("page") || 1;
    //     setCategory(query_category)
    //     axios.get(`${process.env.REACT_APP_CMS}/api/posts?populate[0]=headers_image${query_category ? '&populate[1]=categories&filters[categories][name][$eq]=' + query_category : ''}&pagination[page]=${query_page}&pagination[pageSize]=6`)
    //         .then(({ data }) => {
    //             setBlogs(data.data || []);
    //         })
    // }, [searchParams])
        
    useEffect(() => {
        setIsLoad(true)
        axios.get(`${process.env.REACT_APP_CMS}/api/posts?populate[0]=headers_image&filters[is_highlight][$eq]=1`)
        .then(({ data }) => {
            setTrending(data?.data || []);
            getBlog(data.data)
        })
        // axios.get(`${process.env.REACT_APP_CMS}/api/categories`)
        //     .then(({ data }) => {
        //         setCategories(data?.data || []);
        //     })
    }, [])

    const getBlog = (trends) => {
        axios.get(`${process.env.REACT_APP_CMS}/api/posts?populate[0]=headers_image`)
        .then(({ data }) => {
            const result = data.data;
            const blog = [];
            result.length > 0 && result.forEach((item, key) => {
                if (trends[key] === undefined) {
                    blog.push(item)
                } 
            })
            blog.sort((a,b) => Number(b.attributes.is_highlight) - Number(a.attributes.is_highlight));
            setIsLoad(false)
            setBlogs(blog);
        })
    }

    const showMoreItems = () => {
        setVisible((prevValue) => prevValue + 10)
    }

    const createMarkup = (desc) => {
        let type = ''
        if (desc.length >= 200) {
            type = '...'
        } else {
            type = ''
        }
        return {__html: (isSmallScreen && desc.substring(0,200) + type) || (isMediumScreen && desc.substring(0,200) + type) };
    }

    return (
        <>
            {!isLoad ? 
                <>
                    {blogs.length > 0 || trending.length > 0 ? 
                        <>
                            <Swiper
                                modules={[Navigation, Autoplay]}
                                slidesPerView={1}
                                autoplay={{ delay: 5000 }}
                                navigation={isSmallScreen ? false : true}
                                className='w-[100%]'
                            >   
                                {trending.length > 0 && trending.slice(0,3).map((item, key) => 
                                    <SwiperSlide key={key}>
                                        <div className='relative w-full min-h-screen' style={{ backgroundImage: `url('${api + item.attributes.headers_image.data.attributes.url}')`, backgroundOrigin: 'border-box' , backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundAttachment: 'fixed', backgroundPosition: 'center'}}>
                                            <div style={{background: 'rgba(0, 0, 0,0.4)'}}>
                                                <div className='container mx-auto'>
                                                    <div className='flex justify-center items-center h-screen text-white'>
                                                        <div className='w-screen mt-[20%] lg:mt-[15%] px-[10%] lg:px-[20%]'>
                                                        <h1 className=''>{item.attributes.title}</h1>
                                                        <p className='mt-5 font-thin' dangerouslySetInnerHTML={createMarkup(item.attributes.body)}></p>
                                                        <div className='mt-[5rem] text-center'>
                                                            <button
                                                                className='border-2 border-white py-3 px-20 rounded-full lg:text-xl hover:bg-white hover:text-black'
                                                                onClick={() => navigate(`/blog/${item.attributes.slug}`)}
                                                            >
                                                                READ MORE
                                                            </button>
                                                        </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                )}
                            </Swiper>
                            {blogs.length > 0 && 
                                <div className='mt-10 w-[100%] px-10 container flex flex-wrap lg:flex-nowrap justify-center items-start'>
                                    <div className='w-[100%] lg:w-[50%]'>
                                        <img src={api + blogs[0].attributes.headers_image.data.attributes.url} alt="" className='w-[100%] h-[40vh] object-cover' />
                                        <h2 className='my-3 text-[#34b575]'>{blogs[0].attributes.title}</h2>
                                        <p className='font-thin text-gray-500' dangerouslySetInnerHTML={createMarkup(blogs[0].attributes.body)}></p>
                                        <div 
                                            className='text-blue-600 cursor-pointer text-right hover:underline'
                                            onClick={() => navigate(`/blog/${blogs[0].attributes.slug}`)}
                                        >
                                            Read More
                                        </div>
                                    </div>
                                    <div className='w-[100%] lg:w-[50%] mt-5 lg:mt-0 lg:ml-10'>
                                        {blogs.slice(1,4).map((item, key) => 
                                            <div className='w-[100%] flex flex-wrap lg:flex-nowrap lg:pb-3' key={key}>
                                                <div className='w-[100%] md:w-[40%] lg:w-[30%] md:mb-3 flex justify-center items-center'>
                                                    <img src={api + item.attributes.headers_image.data.attributes.url} alt="" className='w-[100%] md:h-[15vh] lg:h-[20vh] object-cover' />
                                                </div>
                                                <div className='w-[100%] md:w-[60%] lg:w-[70%] py-3 md:py-0 lg:py-0 md:pl-5 lg:pl-5'>
                                                    <h4 className='pb-3 text-[#34b575] lg:text-xl'>{item.attributes.title}</h4>
                                                    <p className='font-thin text-gray-500' dangerouslySetInnerHTML={createMarkup(item.attributes.body)}></p>
                                                    <div 
                                                        className='text-blue-600 cursor-pointer text-right hover:underline'
                                                        onClick={() => navigate(`/blog/${item.attributes.slug}`)}
                                                    >
                                                        Read More
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            }
                            <div className='mt-0 lg:mt-10 px-10 container'>
                                {blogs.length > 0 &&
                                    blogs.slice(4, visible).map((item, key) => 
                                        <div className='w-[100%] flex flex-wrap lg:flex-nowrap lg:pb-5' key={key}>
                                            <div className='w-[100%] md:w-[40%] lg:w-[20%] md:mb-3'>
                                                <img src={api + item.attributes.headers_image.data.attributes.url} alt="" className='w-[100%] md:h-[15vh] lg:h-[16vh] object-cover' />
                                            </div>
                                            <div className='w-[100%] md:w-[60%] lg:w-[80%] py-3 md:py-0 lg:py-0 md:pl-5 lg:pl-5'>
                                                <h4 className='pb-3 text-[#34b575] lg:text-xl'>{item.attributes.title}</h4>
                                                <p className='font-thin text-gray-500' dangerouslySetInnerHTML={createMarkup(item.attributes.body)}>
                                                </p>
                                                <div 
                                                    className='text-blue-600 cursor-pointer text-right hover:underline'
                                                    onClick={() => navigate(`/blog/${item.attributes.slug}`)}
                                                >
                                                    Read More
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                                {visible < blogs.length &&
                                    <div className='text-center mt-5'>
                                        <button 
                                            className='px-10 py-3 lg:py-4 bg-[#34b575] text-white rounded-full text-lg lg:text-xl drop-shadow-lg hover:bg-[#359f6a]'
                                            onClick={() => showMoreItems()}
                                        >
                                            Load More
                                        </button>
                                    </div>
                                }
                            </div>
                        </>
                    : 
                        <NotFound text="blog" />
                    }
                </> 
            : 
                <LoadPage />
            }
            
            
            {/* <div className='w-full md:min-h-screen' style={{ background: 'linear-gradient(180deg, #EFF7F1 17.26%, rgba(255, 255, 255, 0) 100%)' }}>
                <div className='pt-10 md:pt-8 container mx-auto px-8 lg:px-20 justify-center md:min-h-screen items-center lg:flex content-center'>
                    <div>
                        <h1 className="mb-3">
                            Bulurah’s Blog
                        </h1>
                        <p className='font-bold text-2xl mb-6'>Trending</p>
                        <div className='flex' style={{ width: isSmallScreen ? '400' : isMediumScreen ? '600' : '800px' }}>
                            <Swiper
                                modules={[Navigation, Autoplay]}
                                slidesPerView={2}
                                navigation={!isSmallScreen}
                                autoplay={{ delay: 3000, disableOnInteraction: false, pauseOnMouseEnter: true }}
                                style={{ padding: !isSmallScreen ?'0 2rem':'' }}
                            >
                                {tranding.map((data) =>
                                <SwiperSlide key={data.id}>
                                    <div className='px-1 md:px-8 py-2'>
                                        <Link className='mx-auto' style={{ maxWidth: '350px' }} to={`/blog/${data.attributes.slug}`}>
                                                <img className='inline-block mb-2 md:mb-3 rounded-2xl' src={process.env.REACT_APP_CMS + data.attributes.headers_image.data.attributes.formats.small.url} width="350px" alt={data.attributes.title} />
                                            <p className='text-xs md:text-xl inline-block' style={{maxWidth:'350px'}}>{data.attributes.title}</p>
                                        </Link>
                                    </div>
                                </SwiperSlide>
                            )}
                            </Swiper>
                        </div>
                    </div>
                </div>
            </div> */}
            
            {/* <div className='w-full'>
                <div className='container mx-auto px-8 lg:px-20' style={{ maxWidth: '100vw' }}>
                    <p className='font-bold my-6'>Artikel</p>
                    <form className='flex gap-2 md:gap-4' onSubmit={(e)=>e.preventDefault()}>
                        <input type='text' className='shrink w-full px-6 py-4 mr-2 md:mr-0 border-2 rounded-xl' style={{ borderColor: 'rgba(177, 177, 177, 1)' }} name='keyword' placeholder='Cari Artikel'></input>
                        <button type='submit' className='rounded-xl text-white px-6 md:px-16 py-4' style={{ backgroundColor:'rgba(20, 140, 46, 1)'}}>Cari</button>
                    </form>
                    <div className='flex flex-wrap py-6 gap-6 text-xl'>
                        <Link to={`/blog`} className={`text-xs md:text-base ${category ? '' : 'font-bold'}`} style={{
                            color: category ? 'rgba(151, 151, 151, 1)' : 'rgba(20, 140, 46, 1)'
                        }}>Tampilkan Semua</Link>
                        {categories.map(item=>(
                            <Link key={item.id} className={`text-xs md:text-base ${category === item.attributes.name ? 'font-bold' : ''}`} style={{
                                color: category === item.attributes.name ? 
                                    'rgba(20, 140, 46, 1)' : 'rgba(151, 151, 151, 1)'
                                }} to={`/blog?category=${encodeURI(item.attributes.name)}` }>{item.attributes.name}</Link>
                        ))}
                    </div>
                    <div className='grid grid-cols-2 lg:flex flex-wrap gap-2 md:gap-8'>
                        {blogs.data?.map((item) =>
                            <Link key={item.id} to={`/blog/${item.attributes.slug}`}>
                                <img className='mb-2 md:mb-3 rounded-2xl' src={process.env.REACT_APP_CMS + item.attributes.headers_image.data?.attributes.formats.small.url} width="370px" alt={item.attributes.title} />
                                <p className='text-xs md:text-xl' style={{ maxWidth: '370px' }}>{item.attributes.title}</p>
                            </Link>
                        )}
                    </div>
                    <div className='flex items-center justify-center py-8 gap-8'>
                    {(blogs.meta?.pagination.total) ?
                        <>
                            <Link to={`/blog?${category ? `category=${encodeURI(category)}&` : ''}${(blogs.meta.pagination.page - 1 > 0) ? 'page=' + (blogs.meta.pagination.page - 1) : ''}`}>
                                <svg width="19" height="31" viewBox="0 0 19 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.875 28.25L3.125 15.5L15.875 2.75" stroke="#148C2E" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </Link>
                            {[...Array(blogs.meta.pagination.pageCount).keys()].map(item=>
                                item+1 === parseInt(blogs.meta.pagination.page) ?
                                (<p key={item + 1} className='text-lg' style={{color:'rgba(20, 140, 46, 1)'}}>
                                    {item+1}
                                </p>) :
                                    (<Link key={item + 1} className='text-lg' style={{color: 'rgba(151, 151, 151, 1)' }} to={`/blog?${category ? `category=${encodeURI(category)}&`: ''}page=${item+1}`}>
                                    {item+1}
                                </Link>)
                                )}
                            <Link to={`/blog?${category ? `category=${encodeURI(category)}&` : ''}${(blogs.meta.pagination.pageCount - blogs.meta.pagination.page > 0) ? 'page=' + (blogs.meta.pagination.pageCount - blogs.meta.pagination.page) : ''}`}>
                                <svg width="19" height="31" viewBox="0 0 19 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3.125 2.75L15.875 15.5L3.125 28.25" stroke="#148C2E" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </Link>
                        </>
                    :''}
                    </div>
                </div>
            </div> */}
        </>
    )
}
